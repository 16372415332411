import React, { useState } from 'react'

import './landing.scss'
import destotopnersionlogo from '../../Image/destotopnersionlogo.svg'
import logomobile from '../../Image/logomobile.svg'
export default function Index() {

  return (
    <div className='landingPage'>
      <img className='img1' src={destotopnersionlogo} />
      <img className='img2' src={logomobile} />
      <h1>Automating Campus Recruitment For Startups</h1>
    </div>
  )
}
